// import logo from './logo.svg';
import './App.css';
import ChatPage from './ChatPage';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ChatPage />} />
        {/* <Route path="/chat" element={<ChatPage />} /> */}

      </Routes>
    </Router>
  )
}

export default App;
