// require('dotenv').config();

// Asynchronously posts the userIdentifier to an API endpoint
export const postUserIdentifier = async (userIdentifier) => {
    console.log('posting useridentifier', userIdentifier)
    const apiUrl = 'https://api.superdashhq.com/api/v1/webhookstatus/extractConversationDataUsingUserIdentifier/' + process.env.REACT_APP_SUPERDASH_PROJECT_ID + '/'+ userIdentifier; // Replace with your actual API endpoint URL
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_SUPERDASH_API_KEY

        },
        // body: JSON.stringify({ userIdentifier }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('User identifier posted successfully');
      return response;
    } catch (error) {
      console.error('Error posting user identifier:', error);
    }
  };

  export const postUserIdentifierProxy = async (userIdentifier) => { 
    try {
      const response = await fetch('/api/postUserIdentifier', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userIdentifier }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // console.log('postUserIdentifierProxy', response.json());
      return response.json();
    } catch (error) {
      console.error('Error posting user identifier:', error.message);
    }
  };

export const postUserIdentifierBeacon = (userParams) => {
    const apiUrl = 'https://80r1uiiwg8.execute-api.eu-west-2.amazonaws.com/ConsiliumDeploymentStage/triggerExtraction'; // Replace with your actual API endpoint URL
    console.log("POSTING BEACON");
    // 'projectId': '65fc2be19e6e4353ee27d7df', 'api_key': '2324b39a-a970-4c3b-b8d6-4e29635d2d1d'
    // const data = JSON.stringify({ userIdentifier });
    userParams.projectId = process.env.REACT_APP_SUPERDASH_PROJECT_ID;
    userParams.api_key = process.env.REACT_APP_SUPERDASH_API_KEY;
    const data = JSON.stringify(userParams);
    
    // Use the Beacon API to send the data
    navigator.sendBeacon(apiUrl, data);
    // navigator.sendBeacon(apiUrl, userIdentifier);
}

export const postUserIdentifierBeaconProxy = (userParams) => {
  console.log("POSTING PROXY", userParams);
  
  const apiUrl = '/api/postUserIdentifierBeacon';
  const data = JSON.stringify({ userParams });

  // Use the Beacon API to send the data
  navigator.sendBeacon(apiUrl, data);
}


  export const sendUserDetails = async (userParams) => {
    // const url = "https://80r1uiiwg8.execute-api.eu-west-2.amazonaws.com/ConsiliumDeploymentStage/ingest-user-details";
    const url = process.env.REACT_APP_INGEST_USER_DETAILS_URL;
  
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_USER_DETAILS_KEY // user details key
    };
  
    try {
        const userParamsBody = {'body': JSON.stringify(userParams)};
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(userParamsBody),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      // console.log('Response Data:', data);
      return data.body.inserted_id; // Assuming the response JSON contains the inserted_id field directly
    } catch (error) {
      console.error("Error sending user details:", error);
    }
  }

  export const sendUserDetailsProxy = async (userParams) => {
    console.log('userDetailsProxy');
    try {
      const response = await fetch('/api/sendUserDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userParams }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Received by proxy", data);
      // const body = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
      return data.inserted_id; // Assuming the response JSON contains the inserted_id field directly
  
    } catch (error) {
      console.error('Error sending user details proxy:', error.message);
    }
  };

  export const sendPhoneRequest = async (userParams) => {
    // const url = "https://80r1uiiwg8.execute-api.eu-west-2.amazonaws.com/ConsiliumDeploymentStage/ingest-data";
    const url = process.env.REACT_APP_INGEST_DATA_URL;
  
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_INGEST_DATA_KEY // consilium user details key
    };
  
    try {
        userParams.conversationId = userParams.userIdentifier;
        userParams.phoneRequest = true;
        // const userParamsBody = JSON.stringify(userParams);
        // console.log('submitting userParams:', userParams);
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(userParams),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      // console.log('Response Data:', data);
      return data.body.inserted_id; // Assuming the response JSON contains the inserted_id field directly
    } catch (error) {
      console.error("Error sending user details:", error);
    }
  }

  export const sendPhoneRequestProxy = async (userParams) => {
    try {
      const response = await fetch('/api/sendPhoneRequest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userParams }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Response Data:', data);
      return data.inserted_id; // Assuming the response JSON contains the inserted_id field directly
  
    } catch (error) {
      console.error('Error sending user details:', error.message);
    }
  };

  


// receive conversationHistory from superdash API using GET https://api.superdashhq.com/api/v1/conversation/project/65f1482ab13952744fc4702a/:userIdentifier
// test userIdentifier: 66154a0c3c1b641d7ce6b2d9

// console.log('updatingConversationHistory')
// console.log('posting conversation:', receivedData.data.conversationHistory);
// var targetOrigin = "https://superdashhq.com";
                   
// iframe.contentWindow.postMessage({
//   action: "updateConversations",
//   conversations: receivedData.data.conversationHistory
// }, targetOrigin);
  

//   phone request flow
// 1. attempt conversation extraction using postUserIdentifier
// 2. if response != 200, send details directly to ingest API

export const getConversationByUserIdentifier = async (userIdentifier) => {
  const apiUrl = 'https://api.superdashhq.com/api/v1/conversation/project/65f1482ab13952744fc4702a/'+userIdentifier; 
  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_SUPERDASH_API_KEY

      },
      // body: JSON.stringify({ userIdentifier }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // const data = await response.json();
    // console.log('Retrieved conversation: ', data);
    return response;
    // let responseObj = response.json()
    // console.log("messages:", responseObj);
  } catch (error) {
    console.error('Error retrieving conversation', error);
    return null
  }
};
